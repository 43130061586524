<template>
  <div class="payment-amount">
    <titleVue title="支付金额" />
    <div id="paymentAmount" style="height: 275px; width: 100%"></div>
  </div>
</template>
<script>
  import titleVue from './title.vue'
  import * as echarts from 'echarts'
  import { moneyColumnarPic } from '@/api/home/newIndex'
  export default {
    name: '',
    components: { titleVue },
    data() {
      return {
        rightChart: null,
        rightOption: {
          tooltip: {
            trigger: 'axis',
          },
          grid: {
            left: '8%',
            right: '8%',
            bottom: '1%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            data: [],
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              data: [],
              type: 'bar',
              barWidth: '30%',
            },
          ],
        },
      }
    },
    created() {},
    mounted() {
      this.getRightData()
      window.addEventListener('resize', () => {
        this.rightChart.resize()
      })
    },
    methods: {
      async getRightData() {
        const rightDom = document.getElementById('paymentAmount')
        this.rightChart = echarts.init(rightDom)
        const {
          data: { category, data },
        } = await moneyColumnarPic()
        this.rightOption.xAxis.data = category
        this.rightOption.series[0].data = data
        this.rightChart.setOption(this.rightOption)
      },
    },
  }
</script>
<style scoped lang="scss">
  .payment-amount {
    background-color: #fff;
    #paymentAmount {
      border: 0.5px solid #e4e6e9;
    }
  }
</style>
