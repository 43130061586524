<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          :model="queryParam"
          @submit.native.prevent
        >
          <el-form-item label="标题">
            <el-input v-model="queryParam.keywords" placeholder="关键字" />
          </el-form-item>

          <el-form-item label="发布状态" label-width="100px">
            <el-select
              v-model="queryParam.putaway"
              clearable
              placeholder="请选择发布状态"
            >
              <el-option
                v-for="item in putaway"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="是否置顶" label-width="100px">
            <el-select
              v-model="queryParam.isTop"
              clearable
              placeholder="请选择是否置顶"
            >
              <el-option
                v-for="item in isTop"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="loadData(1)"
            >
              查询
            </el-button>

            <el-button icon="el-icon-refresh-left" @click="searchReset">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>

      <vab-query-form-top-panel>
        <el-form ref="form" :inline="true">
          <el-form-item>
            <!-- <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="loadData(1)"
            >
              查询
            </el-button> -->

            <el-button icon="el-icon-plus" type="primary" @click="openAdd">
              新增
            </el-button>

            <!-- <el-button icon="el-icon-edit" type="primary" @click="openEdit">
              修改
            </el-button> -->
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-delete"
              native-type="submit"
              type="danger"
              @click="batchDel"
            >
              删除
            </el-button>
          </el-form-item>

          <el-form-item>
            <!-- <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="refresh"
            >
              刷新
            </el-button> -->
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="dataSource"
      stripe
      @select="onSelectChange"
      @select-all="onSelectChange"
    >
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="分类名称 "
        prop="categoryName"
        width="180"
      />

      <el-table-column align="center" label="排序" prop="seq" width="180" />
      <el-table-column align="center" label="图标" prop="icon">
        <!-- <template slot-scope="scope">
          <img height="50" :src="scope.row.icon" width="50" />
        </template> -->

        <template slot-scope="scope">
          <el-image
            v-if="scope.row.icon"
            :preview-src-list="[scope.row.icon]"
            :src="scope.row.icon"
            style="width: 50px; height: 50px"
          />
          <el-image
            v-else
            :preview-src-list="[defaultImg]"
            :src="defaultImg"
            style="width: 50px; height: 50px"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="发布状态"
        prop="putaway"
        width="180"
      >
        <template #default="{ row }">
          <el-switch
            v-model="row.putaway"
            active-color="#1890FF"
            :active-value="1"
            inactive-color="#A9A8A8"
            :inactive-value="0"
            @change="putTurn(row)"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" label="是否置顶" prop="isTop" width="180">
        <template #default="{ row }">
          <el-switch
            v-model="row.isTop"
            active-color="#1890FF"
            :active-value="1"
            inactive-color="#A9A8A8"
            :inactive-value="0"
            @change="turn(row)"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" label="备注" prop="remark" />
      <el-table-column
        align="center"
        label="置顶时间"
        prop="topTime"
        width="180"
      />
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        width="180"
      />
      <el-table-column
        align="center"
        label="更新时间"
        prop="updateTime"
        width="180"
      />

      <el-table-column align="center" fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="openEdit(scope.row)">
            修改
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Pagination
      :limit.sync="queryParam.pageSize"
      :page.sync="queryParam.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />

    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="editParam"
          label-width="100px"
          :model="editParam"
          :rules="rules"
          size="small"
        >
          <el-row>
            <el-form-item label="分类名称" prop="categoryName">
              <el-input
                v-model="editParam.categoryName"
                clearable
                placeholder="请输入分类名称"
                style="max-width: 500px"
              />
            </el-form-item>
            <el-form-item label="置顶状态" prop="isTop">
              <el-radio-group v-model="editParam.isTop">
                <el-radio
                  v-for="item in isTop"
                  :key="item.value"
                  :label="item.value"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>

              <!-- <el-select
                v-model="editParam.isTop"
                placeholder="是否置顶"
                style="width: 500px"
              >
                <el-option
                  v-for="item in isTop"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select> -->
            </el-form-item>
            <el-form-item label="发布状态" prop="putaway">
              <el-radio-group v-model="editParam.putaway">
                <el-radio
                  v-for="item in putaway"
                  :key="item.value"
                  :label="item.value"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>

              <!-- <el-select
                v-model="editParam.putaway"
                placeholder="发布状态"
                style="width: 500px"
              >
                <el-option
                  v-for="item in putaway"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select> -->
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="editParam.remark"
                clearable
                placeholder="请输入备注"
                style="max-width: 500px"
              />
            </el-form-item>
            <el-form-item label="排序" prop="seq">
              <el-input-number
                v-model="editParam.seq"
                clearable
                :min="1"
                placeholder="请输入排序"
                style="max-width: 500px"
              />
            </el-form-item>
            <div
              style="
                padding-left: 100px;
                margin-top: -10px;
                margin-bottom: 4px;
                font-size: 14px;
                color: #ccc;
              "
            >
              提示：按排序号数字升序排序，数字越小，排序越前
            </div>

            <el-form-item label="分类图标" prop="icon">
              <JMUpload
                v-model="editParam.icon"
                :multiple="false"
                :number="1"
                :value="editParam.icon"
                @change="handleChange"
              />
            </el-form-item>
          </el-row>
        </el-form>
      </template>
    </Dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import { BasePostMixin } from '@/mixins/BasePostMixin'
  import Dialog from '@/components/dialog.vue'
  import JMUpload from '@/components/jm-upload'
  export default {
    name: 'HelpCenterClassify',
    components: {
      Dialog,
      Pagination,
      JMUpload,
    },
    mixins: [BasePostMixin],
    data() {
      return {
        defaultImg: require('@/assets/default.jpg'),
        dailog: {
          height: 620,
          title: '',
          visible: false,
          width: '800px',
        },
        disabled: false,
        loading: false,
        direction: 'rtl',
        size: '',
        putaway: [
          {
            value: 1,
            label: '已发布',
          },
          {
            value: 0,
            label: '未发布',
          },
        ],
        isTop: [
          {
            value: 0,
            label: '否',
          },
          {
            value: 1,
            label: '是',
          },
        ],
        selection: [],
        rules: {
          categoryName: [
            { required: true, message: '请输入分类名称', trigger: 'blur' },
          ],
          isTop: [
            { required: true, message: '请选择置顶状态', trigger: 'blur' },
          ],
          title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
          seq: [{ required: true, message: '请输入排序', trigger: 'blur' }],
          icon: [
            { required: true, message: '请上传分类图标', trigger: 'blur' },
          ],
          putaway: [
            { required: true, message: '请选择发布状态', trigger: 'blur' },
          ],
        },
        url: {
          list: '/admin/help/category/page',
          add: '/admin/help/category/add',
          deleteBatch: '/admin/help/category/delete',
          handleEditUrl: '/admin/help/category/update',
        },
      }
    },
    created() {},
    methods: {
      handleChange(value) {
        console.log('value', value)
        //this.editParam.icon = value
      },
      // 确定新增/修改
      handleConfirm() {
        this.$refs['editParam'].validate((valid) => {
          console.log(valid)
          if (valid) {
            console.log('valid', valid)
            console.log('this.editParam', this.editParam)
            if (this.editParam.id) {
              console.log('编辑')
              this.handEdit(this.editParam)
            } else {
              this.handleAdd()
            }
          }
        })
      },
      openAdd() {
        console.log('新增开启')
        this.dailog.visible = true
        this.dailog.title = '新增'
      },
      openEdit(row) {
        // if (this.selectedRowKeys.length <= 0) {
        //   this.$message.warning('请选择一条记录！')
        //   return
        // } else {
        this.dailog.visible = true
        this.dailog.title = '修改'
        this.editParam = row
        console.log(' this.selectionRows ', row)
        // }
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.editParam = {}
        // this.$refs['editParam'].resetFields()
      },
      turn(row) {
        console.log(row, 488)
        this.handEdit(row)
      },
      putTurn(row) {
        this.handEdit(row)
      },
      handleClose() {
        this.drawer = false
      },
      //查询
      getList() {
        this.loadData()
      },
      handView(row) {
        console.log('row', row)
        this.drawer = true
        this.drawerData = row
      },
      //刷新
      refresh() {
        this.loadData(1)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }

  .el-dialog .el-form-item__label {
    width: 150px !important;
  }
</style>
