<template>
  <div class="user-data">
    <titleVue title="用户数据" />
    <div class="user-data-box">
      <div class="user-data-item">
        <div class="item-title">{{ tableList[0].label }}</div>
        <div class="item-center">
          <div class="item-center-left">
            <img alt="" src="@/assets/data_overview/icon-daishenhe2.svg" />
            <el-tooltip
              class="item"
              :content="tableList[0].value + ''"
              effect="dark"
              placement="top-start"
            >
              <span>{{ tableList[0].value }}</span>
            </el-tooltip>
          </div>
          <div
            class="item-center-right"
            @click="skipUrl('ShopApplylist', 'status', 2)"
          >
            去审核
            <i class="iconfont icon-jiantou"></i>
          </div>
        </div>
        <div class="item-footer">
          今日
          <span>{{ tableList[0].added }}</span>
          <i class="iconfont icon-shangsheng1"></i>
        </div>
      </div>
      <div class="line"></div>
      <div class="user-data-item">
        <div class="item-title">{{ tableList[4].label }}</div>
        <div class="item-center">
          <div class="item-center-left">
            <img alt="" src="@/assets/data_overview/icon-shanghu.svg" />
            <el-tooltip
              class="item"
              :content="tableList[4].value + ''"
              effect="dark"
              placement="top-start"
            >
              <span>{{ tableList[4].value }}</span>
            </el-tooltip>
          </div>
          <!-- <div class="item-center-right">
            去审核
            <i class="iconfont icon-jiantou"></i>
          </div> -->
        </div>
        <div class="item-footer">
          今日
          <span>{{ tableList[4].added }}</span>
          <i class="iconfont icon-shangsheng1"></i>
        </div>
      </div>
    </div>
    <div class="user-data-box">
      <div class="user-data-item">
        <div class="item-title">{{ tableList[1].label }}</div>
        <div class="item-center">
          <div class="item-center-left">
            <img alt="" src="@/assets/data_overview/icon-daishenhe2.svg" />
            <el-tooltip
              class="item"
              :content="tableList[1].value + ''"
              effect="dark"
              placement="top-start"
            >
              <span>{{ tableList[1].value }}</span>
            </el-tooltip>
          </div>
          <div
            class="item-center-right"
            @click="skipUrl('DistributorManageApply', 'status', 0)"
          >
            去审核
            <i class="iconfont icon-jiantou"></i>
          </div>
        </div>
        <div class="item-footer">
          今日
          <span>{{ tableList[1].added }}</span>
          <i class="iconfont icon-shangsheng1"></i>
        </div>
      </div>
      <div class="line"></div>
      <div class="user-data-item">
        <div class="item-title">{{ tableList[5].label }}</div>
        <div class="item-center">
          <div class="item-center-left">
            <img alt="" src="@/assets/data_overview/icon-qudaoshang.svg" />
            <el-tooltip
              class="item"
              :content="tableList[5].value + ''"
              effect="dark"
              placement="top-start"
            >
              <span>{{ tableList[5].value }}</span>
            </el-tooltip>
          </div>
          <!-- <div class="item-center-right">
            去审核
            <i class="iconfont icon-jiantou"></i>
          </div> -->
        </div>
        <div class="item-footer">
          今日
          <span>{{ tableList[5].added }}</span>
          <i class="iconfont icon-shangsheng1"></i>
        </div>
      </div>
    </div>
    <div class="user-data-box">
      <div class="user-data-item">
        <div class="item-title">{{ tableList[6].label }}</div>
        <div class="item-center">
          <div class="item-center-left">
            <img alt="" src="@/assets/data_overview/icon-shanghu.svg" />
            <el-tooltip
              class="item"
              :content="tableList[6].value + ''"
              effect="dark"
              placement="top-start"
            >
              <span>{{ tableList[6].value }}</span>
            </el-tooltip>
          </div>
          <!-- <div class="item-center-right">
            去审核
            <i class="iconfont icon-jiantou"></i>
          </div> -->
        </div>
        <div class="item-footer">
          今日
          <span>{{ tableList[6].added }}</span>
          <i class="iconfont icon-shangsheng1"></i>
        </div>
      </div>
      <div class="line"></div>
      <div class="user-data-item">
        <div class="item-title">{{ tableList[7].label }}</div>
        <div class="item-center">
          <div class="item-center-left">
            <img alt="" src="@/assets/data_overview/icon-pingtaiyonghu.svg" />
            <el-tooltip
              class="item"
              :content="tableList[7].value + ''"
              effect="dark"
              placement="top-start"
            >
              <span>{{ tableList[7].value }}</span>
            </el-tooltip>
          </div>
          <!-- <div class="item-center-right">
            去审核
            <i class="iconfont icon-jiantou"></i>
          </div> -->
        </div>
        <div class="item-footer">
          今日
          <span>{{ tableList[7].added }}</span>
          <i class="iconfont icon-shangsheng1"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import titleVue from './title.vue'
  export default {
    name: '',
    components: { titleVue },
    props: {
      tableList: {
        type: Array,
        default: () => {
          return []
        },
      },
    },
    data() {
      return {}
    },
    created() {},
    methods: {
      skipUrl(val, key, value) {
        this.$router.push({
          name: val,
          query: { [key]: value },
        })
      },
    },
  }
</script>
<style scoped lang="scss">
  .user-data {
    background-color: #fff;
    .user-data-box {
      border: 0.5px solid #e4e6e9;
      display: flex;
      .user-data-item {
        height: 184px;
        flex: 1;
        box-sizing: border-box;
        padding: 39px 17px 20px 20px;
        .item-title {
          font-weight: 400;
          font-size: 16px;
          color: #666;
          margin-bottom: 13px;
        }
        .item-center {
          align-items: center;
          display: flex;
          margin-bottom: 14px;
          .item-center-left {
            align-items: center;
            display: flex;
            img {
              width: 30px;
              height: 30px;
              margin-right: 11px;
            }
            span {
              font-weight: 700;
              font-size: 25px;
              color: #1f1f1f;
            }
          }
          .item-center-right {
            font-weight: 400;
            font-size: 14px;
            color: #126dff;
            flex: 1;
            cursor: pointer;
            text-align: right;
            i {
              font-size: 8px;
            }
          }
        }
        .item-footer {
          font-weight: 400;
          font-size: 16px;
          color: #999;
          span {
            font-weight: 400;
            font-size: 16px;
            color: #34d1c9;
            margin: 0 6px;
          }
          i {
            color: #34d1c9;
          }
        }
      }
      .line {
        height: 88px;
        width: 0.5px;
        margin-top: 50px;
        background-color: #e4e6e9;
      }
    }
  }
</style>
