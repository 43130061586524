<template>
  <div class="merchant-city-distribution">
    <titleVue title="商户城市分布" />
    <div class="gd-map">
      <gdMap />
    </div>
  </div>
</template>
<script>
  import gdMap from './map'
  import titleVue from './title.vue'
  export default {
    name: '',
    components: { titleVue, gdMap },
    data() {
      return {}
    },
    created() {},
  }
</script>
<style scoped lang="scss">
  .merchant-city-distribution {
    background-color: #fff;
    .gd-map {
      border: 0.5px solid #e4e6e9;
    }
  }
</style>
