var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "merchant-listing-ranking" },
    [
      _c("titleVue", { attrs: { title: "商户房源排行" } }),
      _c(
        "div",
        { staticClass: "merchant-listing-ranking-table" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, height: "295" },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function () {
                    return [
                      _c("el-image", {
                        staticClass: "vab-data-empty",
                        attrs: {
                          src: require("@/assets/empty_images/data_empty.png"),
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("el-table-column", {
                attrs: { label: "排名", type: "index", width: "50" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "姓名",
                  prop: "name",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", { attrs: { label: "数量", prop: "num" } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }