<template>
  <div class="index-container">
    <el-page-header :content="tabTitle" @back="goBack" />
    <el-form
      ref="formData"
      label-width="100px"
      :model="formData"
      :rules="rules"
      size="small"
    >
      <el-row>
        <el-form-item label="帮助类别" prop="helpCategoryId">
          <el-select
            v-model="formData.helpCategoryId"
            placeholder="请选择帮助类别"
            style="width: 500px"
          >
            <el-option
              v-for="item in helpCategoryList"
              :key="item.id"
              :label="item.categoryName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="作者" prop="author">
          <el-input
            v-model="formData.author"
            clearable
            placeholder="请输入作者"
            style="max-width: 500px"
          />
        </el-form-item>
        <el-form-item label="文章标题" prop="helpTitle">
          <el-input
            v-model="formData.helpTitle"
            clearable
            placeholder="请输入文章标题"
            style="max-width: 500px"
          />
        </el-form-item>
        <el-form-item label="文章摘要" prop="digest">
          <el-input
            v-model="formData.digest"
            clearable
            placeholder="请输入文章摘要"
            style="max-width: 500px"
          />
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <JEditor v-model="formData.content" />
        </el-form-item>
        <el-form-item :label="'文章图标'" prop="helpLogo">
          <JMUpload
            v-if="formData.helpLogo != ''"
            v-model="formData.helpLogo"
            :multiple="false"
            :number="1"
            @change="handleChange"
          />
        </el-form-item>
        <el-form-item label="置顶状态" prop="isTop">
          <el-radio-group v-model="formData.isTop">
            <el-radio
              v-for="item in topList"
              :key="item.value"
              :label="item.value"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>

          <!-- <el-select
            v-model="formData.isTop"
            placeholder="请选择发布状态"
            style="width: 500px"
          >
            <el-option
              v-for="item in topList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select> -->
        </el-form-item>
        <el-form-item label="发布状态" prop="putaway">
          <el-radio-group v-model="formData.putaway">
            <el-radio
              v-for="item in putAwayList"
              :key="item.value"
              :label="item.value"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>

          <!-- <el-select
            v-model="formData.putaway"
            placeholder="请选择发布状态"
            style="width: 500px"
          >
            <el-option
              v-for="item in putAwayList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select> -->
        </el-form-item>

        <el-button
          v-if="tabUpdate == 0"
          icon="el-icon-circle-plus"
          style="
            margin-left: 20px;
            display: flex;
            width: 200px;
            justify-content: center;
          "
          type="primary"
          @click="handleConfirm()"
        >
          确认添加
        </el-button>

        <el-button
          v-if="tabUpdate == 1"
          icon="el-icon-edit-outline"
          type="primary"
          @click="handleConfirm(formData)"
        >
          确认修改
        </el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { handleActivePath } from '@/utils/routes'
  import {
    getHelpCategory,
    getHelpInformationDetail,
    addHelpInformation,
    editHelpInformation,
  } from '@/api/help/help'
  import JMUpload from '@/components/jm-upload'
  import JEditor from '@/components/j-editor'

  export default {
    name: 'InformationOperation',
    components: {
      JMUpload,
      JEditor,
    },
    beforeRouteEnter: (to, from, next) => {
      console.log('111:', to, from, next)
      if (to.query.id == 0) {
        to.meta.title = '新增帮助信息'
      } else {
        to.meta.title = '修改帮助信息'
      }
      next()
      console.log(to, 'tttt')
    },
    data() {
      return {
        formData: {},
        tabUpdate: 0,
        tabTitle: '',

        putAwayList: [
          {
            label: '未发布',
            value: 0,
          },
          {
            label: '已发布',
            value: 1,
          },
        ],
        topList: [
          {
            label: '未置顶',
            value: 0,
          },
          {
            label: '已置顶',
            value: 1,
          },
        ],
        helpCategoryList: [],
        rules: {
          helpCategoryId: [
            { required: true, message: '请选择帮助类别', trigger: 'blur' },
          ],
          author: [{ required: true, message: '请输入作者', trigger: 'blur' }],
          helpTitle: [
            { required: true, message: '请输入标题', trigger: 'blur' },
          ],
          digest: [
            { required: true, message: '请输入文章摘要', trigger: 'blur' },
          ],
          content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
          helpLogo: [
            { required: true, message: '请上传文章图标', trigger: 'blur' },
          ],
          isTop: [
            { required: true, message: '请选择置顶状态', trigger: 'blur' },
          ],
          putaway: [
            { required: true, message: '请选择发布状态', trigger: 'blur' },
          ],
        },
      }
    },
    created() {
      this.getHelpCategory()
      this.id = this.$route.query.id
      // console.log('id:', this.id)
      if (this.id == 0) {
        this.tabUpdate = 0
        this.tabTitle = '新增帮助信息'
        console.log('新增')
      } else {
        this.tabUpdate = 1
        this.tabTitle = '修改帮助信息'
        this.getHelpInformationDetail()
      }
    },
    methods: {
      getHelpCategory() {
        const param = { pageNumber: 1, pageSize: 20 }
        console.log(param)
        getHelpCategory(param).then((res) => {
          console.log('res', res)
          const { records } = res.data
          this.helpCategoryList = records
        })
      },
      getHelpInformationDetail() {
        const param = { id: this.id }
        console.log('id', param)
        getHelpInformationDetail(param).then((res) => {
          console.log('res', res)
          this.formData = res.data
          console.log('this.formData：', this.formData)
        })
      },

      handleChange(value) {
        console.log('value', value)
        this.formData.helpLogo = value
      },
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      async goBack() {
        const detailPath = await handleActivePath(this.$route, true)
        await this.$router.push('/helpcenter/information')
        await this.delVisitedRoute(detailPath)
      },
      // 确定新增/修改
      handleConfirm() {
        console.log('this.$route.meta.title:', this.$route.meta.title)
        this.$refs['formData'].validate((valid) => {
          console.log(valid)
          if (valid) {
            console.log('this.formData:', this.formData)
            this.formData.title = this.formData.helpTitle
            this.formData.logo = this.formData.helpLogo
            this.$delete(this.formData, 'helpTitle')
            this.$delete(this.formData, 'helpLogo')
            if (this.$route.meta.title === '新增帮助信息') {
              addHelpInformation(this.formData).then((res) => {
                this.commonMessage(res)
                this.goBack()
              })
            } else {
              editHelpInformation(this.formData).then((res) => {
                this.commonMessage(res)
                this.goBack()
              })
            }
          }
        })
      },
      commonMessage(res) {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          // this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
          })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.personal-center';
  #{$base}-container {
    padding: 0 !important;

    #{$base}-user-info {
      padding: $base-padding;
      text-align: center;

      :deep() {
        .el-avatar {
          img {
            cursor: pointer;
          }
        }
      }

      &-full-name {
        margin-top: 15px;
        font-size: 24px;
        font-weight: 500;
        color: #262626;
      }

      &-description {
        margin-top: 8px;
      }

      &-follow {
        margin-top: 15px;
      }

      &-list {
        margin-top: 18px;
        line-height: 30px;
        text-align: left;
        list-style: none;

        h5 {
          margin: -20px 0 5px 0;
        }

        :deep() {
          .el-tag {
            margin-right: 10px !important;
          }

          .el-tag + .el-tag {
            margin-left: 0;
          }
        }
      }
    }

    #{$base}-item {
      display: flex;

      i {
        font-size: 40px;
      }

      &-content {
        box-sizing: border-box;
        flex: 1;
        margin-left: $base-margin;

        &-second {
          margin-top: 8px;
        }
      }
    }
  }
  .card-title {
    width: 60px;
    border-bottom: 2px solid #000;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .imageInfo {
    width: 200px;
    height: 100px;
  }
</style>
