<template>
  <div class="listings-others">
    <titleVue title="房源&其他" />
    <div
      v-for="(item, index) in listingsOthersList"
      :key="index"
      class="listings-others-item"
    >
      <div class="item-title">{{ item.name }}</div>
      <div class="item-center">
        <div class="item-center-left">
          <img alt="" :src="item.icon" />
          <el-tooltip
            class="item"
            :content="item.number + ''"
            effect="dark"
            placement="top-start"
          >
            <span>{{ item.number }}</span>
          </el-tooltip>
        </div>
        <!-- <div class="item-center-right">
          去审核
          <i class="iconfont icon-jiantou"></i>
        </div> -->
      </div>
      <div class="item-footer">
        今日
        <span>{{ item.todayNumber }}</span>
        <i class="iconfont icon-shangsheng1"></i>
      </div>
    </div>
  </div>
</template>
<script>
  import titleVue from './title.vue'
  export default {
    name: '',
    components: { titleVue },
    props: {
      tableList: {
        type: Array,
        default: () => {
          return []
        },
      },
    },
    data() {
      return {
        listingsOthersList: [
          {
            name: this.tableList[2].label,
            number: this.tableList[2].value,
            todayNumber: this.tableList[2].added,
            icon: require('@/assets/data_overview/icon-fangyuan.svg'),
          },
          {
            name: this.tableList[3].label,
            number: this.tableList[3].value,
            todayNumber: this.tableList[3].added,
            icon: require('@/assets/data_overview/icon-fangyuan.svg'),
          },
          {
            name: this.tableList[10].label,
            number: this.tableList[10].value,
            todayNumber: this.tableList[10].added,
            icon: require('@/assets/data_overview/icon-chuhuoshebei.svg'),
          },
        ],
      }
    },
    created() {},
  }
</script>
<style scoped lang="scss">
  .listings-others {
    background-color: #fff;
    .listings-others-item {
      height: 186px;
      box-sizing: border-box;
      border: 0.5px solid #e4e6e9;
      padding: 39px 17px 20px 20px;
      .item-title {
        font-weight: 400;
        font-size: 16px;
        color: #666;
        margin-bottom: 13px;
      }
      .item-center {
        align-items: center;
        display: flex;
        margin-bottom: 14px;
        .item-center-left {
          align-items: center;
          display: flex;
          img {
            width: 30px;
            height: 30px;
            margin-right: 11px;
          }
          span {
            font-weight: 700;
            font-size: 25px;
            color: #1f1f1f;
          }
        }
        .item-center-right {
          font-weight: 400;
          cursor: pointer;
          font-size: 14px;
          color: #126dff;
          flex: 1;
          text-align: right;
          i {
            font-size: 8px;
          }
        }
      }
      .item-footer {
        font-weight: 400;
        font-size: 16px;
        color: #999;
        span {
          font-weight: 400;
          font-size: 16px;
          color: #1990ff;
          margin: 0 6px;
        }
        i {
          color: #1990ff;
        }
      }
    }
  }
</style>
