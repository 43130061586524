<template>
  <div class="merchant-growth">
    <titleVue title="商户增长">
      <div class="btn-wrap">
        <span :class="{ active: rightActive === 1 }" @click="toggleMid(1)">
          近30天
        </span>
        <span :class="{ active: rightActive === 2 }" @click="toggleMid(2)">
          年度
        </span>
      </div>
    </titleVue>
    <div id="merchantGrowth" style="height: 268px; width: 100%"></div>
  </div>
</template>
<script>
  import titleVue from './title.vue'
  import * as echarts from 'echarts'
  import { shopBrokenLine, shopYearBrokenLine } from '@/api/home/newIndex'
  export default {
    name: '',
    components: { titleVue },
    data() {
      return {
        rightActive: 1,
        midChart: null,
        midOption: {
          tooltip: {
            trigger: 'axis',
          },
          grid: {
            left: '5%',
            right: '5%',
            bottom: '1%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            data: [],
          },

          yAxis: {
            type: 'value',
          },
          series: [
            {
              data: [],
              type: 'line',
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    // 渐变颜色
                    {
                      offset: 0,
                      color: '#5470C6',
                    },
                    {
                      offset: 1,
                      color: '#fff',
                    },
                  ],
                },
              },
            },
          ],
        },
      }
    },
    created() {},
    mounted() {
      this.getMidData()
      window.addEventListener('resize', () => {
        this.midChart.resize()
      })
    },
    methods: {
      async getMidData() {
        const midDom = document.getElementById('merchantGrowth')
        this.midChart = echarts.init(midDom)
        await this.toggleMid(1)
        this.midChart.setOption(this.midOption)
      },
      async toggleMid(val) {
        this.rightActive = val
        if (val === 1) {
          const {
            data: { category, data },
          } = await shopBrokenLine()
          this.midOption.xAxis.data = category
          this.midOption.series[0].data = data
        } else {
          const {
            data: { category, data },
          } = await shopYearBrokenLine()
          this.midOption.xAxis.data = category
          this.midOption.series[0].data = data
        }
        this.midChart.setOption(this.midOption)
      },
    },
  }
</script>
<style scoped lang="scss">
  .merchant-growth {
    background-color: #fff;

    #merchantGrowth {
      border: 0.5px solid #e4e6e9;
    }

    .btn-wrap {
      font-weight: 400;
      font-size: 16px;
      color: #333;

      span {
        cursor: pointer;
      }

      .active {
        font-weight: 500;
        font-size: 16px;
        color: #1990ff;
      }
    }
  }
</style>
