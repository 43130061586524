var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-data" },
    [
      _c("titleVue", { attrs: { title: "用户数据" } }),
      _c("div", { staticClass: "user-data-box" }, [
        _c("div", { staticClass: "user-data-item" }, [
          _c("div", { staticClass: "item-title" }, [
            _vm._v(_vm._s(_vm.tableList[0].label)),
          ]),
          _c("div", { staticClass: "item-center" }, [
            _c(
              "div",
              { staticClass: "item-center-left" },
              [
                _c("img", {
                  attrs: {
                    alt: "",
                    src: require("@/assets/data_overview/icon-daishenhe2.svg"),
                  },
                }),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      content: _vm.tableList[0].value + "",
                      effect: "dark",
                      placement: "top-start",
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.tableList[0].value))])]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "item-center-right",
                on: {
                  click: function ($event) {
                    return _vm.skipUrl("ShopApplylist", "status", 2)
                  },
                },
              },
              [
                _vm._v(" 去审核 "),
                _c("i", { staticClass: "iconfont icon-jiantou" }),
              ]
            ),
          ]),
          _c("div", { staticClass: "item-footer" }, [
            _vm._v(" 今日 "),
            _c("span", [_vm._v(_vm._s(_vm.tableList[0].added))]),
            _c("i", { staticClass: "iconfont icon-shangsheng1" }),
          ]),
        ]),
        _c("div", { staticClass: "line" }),
        _c("div", { staticClass: "user-data-item" }, [
          _c("div", { staticClass: "item-title" }, [
            _vm._v(_vm._s(_vm.tableList[4].label)),
          ]),
          _c("div", { staticClass: "item-center" }, [
            _c(
              "div",
              { staticClass: "item-center-left" },
              [
                _c("img", {
                  attrs: {
                    alt: "",
                    src: require("@/assets/data_overview/icon-shanghu.svg"),
                  },
                }),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      content: _vm.tableList[4].value + "",
                      effect: "dark",
                      placement: "top-start",
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.tableList[4].value))])]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "item-footer" }, [
            _vm._v(" 今日 "),
            _c("span", [_vm._v(_vm._s(_vm.tableList[4].added))]),
            _c("i", { staticClass: "iconfont icon-shangsheng1" }),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "user-data-box" }, [
        _c("div", { staticClass: "user-data-item" }, [
          _c("div", { staticClass: "item-title" }, [
            _vm._v(_vm._s(_vm.tableList[1].label)),
          ]),
          _c("div", { staticClass: "item-center" }, [
            _c(
              "div",
              { staticClass: "item-center-left" },
              [
                _c("img", {
                  attrs: {
                    alt: "",
                    src: require("@/assets/data_overview/icon-daishenhe2.svg"),
                  },
                }),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      content: _vm.tableList[1].value + "",
                      effect: "dark",
                      placement: "top-start",
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.tableList[1].value))])]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "item-center-right",
                on: {
                  click: function ($event) {
                    return _vm.skipUrl("DistributorManageApply", "status", 0)
                  },
                },
              },
              [
                _vm._v(" 去审核 "),
                _c("i", { staticClass: "iconfont icon-jiantou" }),
              ]
            ),
          ]),
          _c("div", { staticClass: "item-footer" }, [
            _vm._v(" 今日 "),
            _c("span", [_vm._v(_vm._s(_vm.tableList[1].added))]),
            _c("i", { staticClass: "iconfont icon-shangsheng1" }),
          ]),
        ]),
        _c("div", { staticClass: "line" }),
        _c("div", { staticClass: "user-data-item" }, [
          _c("div", { staticClass: "item-title" }, [
            _vm._v(_vm._s(_vm.tableList[5].label)),
          ]),
          _c("div", { staticClass: "item-center" }, [
            _c(
              "div",
              { staticClass: "item-center-left" },
              [
                _c("img", {
                  attrs: {
                    alt: "",
                    src: require("@/assets/data_overview/icon-qudaoshang.svg"),
                  },
                }),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      content: _vm.tableList[5].value + "",
                      effect: "dark",
                      placement: "top-start",
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.tableList[5].value))])]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "item-footer" }, [
            _vm._v(" 今日 "),
            _c("span", [_vm._v(_vm._s(_vm.tableList[5].added))]),
            _c("i", { staticClass: "iconfont icon-shangsheng1" }),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "user-data-box" }, [
        _c("div", { staticClass: "user-data-item" }, [
          _c("div", { staticClass: "item-title" }, [
            _vm._v(_vm._s(_vm.tableList[6].label)),
          ]),
          _c("div", { staticClass: "item-center" }, [
            _c(
              "div",
              { staticClass: "item-center-left" },
              [
                _c("img", {
                  attrs: {
                    alt: "",
                    src: require("@/assets/data_overview/icon-shanghu.svg"),
                  },
                }),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      content: _vm.tableList[6].value + "",
                      effect: "dark",
                      placement: "top-start",
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.tableList[6].value))])]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "item-footer" }, [
            _vm._v(" 今日 "),
            _c("span", [_vm._v(_vm._s(_vm.tableList[6].added))]),
            _c("i", { staticClass: "iconfont icon-shangsheng1" }),
          ]),
        ]),
        _c("div", { staticClass: "line" }),
        _c("div", { staticClass: "user-data-item" }, [
          _c("div", { staticClass: "item-title" }, [
            _vm._v(_vm._s(_vm.tableList[7].label)),
          ]),
          _c("div", { staticClass: "item-center" }, [
            _c(
              "div",
              { staticClass: "item-center-left" },
              [
                _c("img", {
                  attrs: {
                    alt: "",
                    src: require("@/assets/data_overview/icon-pingtaiyonghu.svg"),
                  },
                }),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      content: _vm.tableList[7].value + "",
                      effect: "dark",
                      placement: "top-start",
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.tableList[7].value))])]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "item-footer" }, [
            _vm._v(" 今日 "),
            _c("span", [_vm._v(_vm._s(_vm.tableList[7].added))]),
            _c("i", { staticClass: "iconfont icon-shangsheng1" }),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }