<template>
  <div class="rental-plaza">
    <titleVue title="租房广场" />
    <div
      v-for="(item, index) in rentalPlazaList"
      :key="index"
      class="rental-plaza-item"
    >
      <div class="item-title">{{ item.name }}</div>
      <div class="item-center">
        <div class="item-center-left">
          <img alt="" :src="item.icon" />
          <el-tooltip
            class="item"
            :content="item.number"
            effect="dark"
            placement="top-start"
          >
            <span>{{ item.number }}</span>
          </el-tooltip>
        </div>
        <div v-if="item.isUrl" class="item-center-right" @click="skipPage">
          去审核
          <i class="iconfont icon-jiantou"></i>
        </div>
      </div>
      <div class="item-footer">
        今日
        <span>{{ item.todayNumber }}</span>
        <i class="iconfont icon-shangsheng1"></i>
      </div>
    </div>
  </div>
</template>
<script>
  import titleVue from './title.vue'
  export default {
    name: '',
    components: { titleVue },
    props: {
      tableList: {
        type: Array,
        default: () => {
          return []
        },
      },
    },
    data() {
      return {
        rentalPlazaList: [
          {
            name: this.tableList[11].label,
            number: this.tableList[11].value + '',
            todayNumber: this.tableList[11].added,
            icon: require('@/assets/data_overview/icon-daishenhe.svg'),
            isUrl: true,
          },
          {
            name: this.tableList[12].label,
            number: this.tableList[12].value + '',
            todayNumber: this.tableList[12].added,
            icon: require('@/assets/data_overview/icon-zufangguangchang.svg'),
            isUrl: false,
          },
          {
            name: this.tableList[13].label,
            number: this.tableList[13].value + '',
            todayNumber: this.tableList[13].added,
            icon: require('@/assets/data_overview/icon-remenfangyuan.svg'),
            isUrl: false,
          },
        ],
      }
    },
    created() {},
    methods: {
      skipPage() {
        this.$router.push({
          path: '/houseAudit/index',
          query: { status: 3 },
        })
      },
    },
  }
</script>
<style scoped lang="scss">
  .rental-plaza {
    background-color: #fff;

    .rental-plaza-item {
      height: 186px;
      box-sizing: border-box;
      border: 0.5px solid #e4e6e9;
      padding: 39px 17px 20px 20px;

      .item-title {
        font-weight: 400;
        font-size: 16px;
        color: #666;
        margin-bottom: 13px;
      }

      .item-center {
        align-items: center;
        display: flex;
        margin-bottom: 14px;

        .item-center-left {
          align-items: center;
          display: flex;

          img {
            width: 30px;
            height: 30px;
            margin-right: 8px;
          }

          span {
            font-weight: 700;
            font-size: 25px;
            color: #1f1f1f;
          }
        }

        .item-center-right {
          font-weight: 400;
          font-size: 14px;
          color: #126dff;
          cursor: pointer;
          flex: 1;
          text-align: right;

          i {
            font-size: 8px;
          }
        }
      }

      .item-footer {
        font-weight: 400;
        font-size: 16px;
        color: #999;

        span {
          font-weight: 400;
          font-size: 16px;
          color: #f68e34;
          margin: 0 6px;
        }

        i {
          color: #f68e34;
        }
      }
    }
  }
</style>
