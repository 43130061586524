var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "merchant-city-distribution" },
    [
      _c("titleVue", { attrs: { title: "商户城市分布" } }),
      _c("div", { staticClass: "gd-map" }, [_c("gdMap")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }