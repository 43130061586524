var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "payment-amount" },
    [
      _c("titleVue", { attrs: { title: "支付金额" } }),
      _c("div", {
        staticStyle: { height: "275px", width: "100%" },
        attrs: { id: "paymentAmount" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }