<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="关键字">
            <el-input v-model="listQuery.keywords" placeholder="关键字" />
          </el-form-item>
          <el-form-item label="手机号" label-width="90px">
            <el-input v-model="listQuery.phone" placeholder="手机号" />
          </el-form-item>

          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>

            <!-- <el-button icon="el-icon-refresh" type="primary" @click="refresh">
              刷新
            </el-button> -->
            <el-button icon="el-icon-refresh-left" @click="reset">
              重置
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="danger"
              @click="batchDel"
            >
              删除
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @select="onSelectChange"
      @select-all="onSelectChange"
    >
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="昵称 " prop="name" width="180" />

      <el-table-column align="center" label="手机号" prop="phone" width="180" />
      <el-table-column align="center" label="反馈内容" prop="content" />
      <el-table-column align="center" label="反馈图片" prop="img" width="180">
        <!-- <template slot-scope="scope">
          <img height="50" :src="scope.row.img" width="50" />
        </template> -->

        <!-- <template slot-scope="scope">
          <el-popover placement="right" title="" trigger="hover">
            <el-image
              slot="reference"
              :alt="scope.row.img"
              :src="scope.row.img"
              style="max-width: 100px; max-height: 100px"
            />
            <el-image
              :src="scope.row.img"
              style="max-width: 300px; max-height: 300px"
            />
          </el-popover>
        </template> -->

        <template slot-scope="scope">
          <el-image
            v-if="scope.row.img"
            :preview-src-list="[scope.row.img]"
            :src="scope.row.img"
            style="width: 50px; height: 50px"
          />
          <el-image
            v-else
            :preview-src-list="[defaultImg]"
            :src="defaultImg"
            style="width: 50px; height: 50px"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="反馈时间"
        prop="createTime"
        width="180"
      />
      <el-table-column align="center" fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handView(scope.row)">
            详情
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
    >
      <template #content>
        <el-descriptions
          border
          class="margin-top"
          :column="2"
          size="medium"
          title=""
        >
          <el-descriptions-item>
            <template slot="label">昵称</template>
            {{ drawerData.name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">手机号</template>
            {{ drawerData.phone }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">反馈内容</template>
            {{ drawerData.content }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">反馈图片</template>
            <el-image
              :preview-src-list="[drawerData.img]"
              :src="drawerData.img"
              style="width: 50px; height: 50px"
            />
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">反馈时间</template>
            {{ drawerData.createTime }}
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </Dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import { feedBackList, deleteBatch } from '@/api/help/feedback'
  import Dialog from '@/components/dialogOther.vue'
  export default {
    name: 'HelpCenterFeedback',
    components: {
      Pagination,
      Dialog,
    },

    data() {
      return {
        dailog: {
          height: 180,
          title: '意见反馈',
          visible: false,
          width: '700px',
        },
        defaultImg: require('@/assets/default.jpg'),
        drawerData: {},
        disabled: false,
        loading: false,
        size: '',
        selection: [],
        tableData: [],
        totalCount: 0,
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          keywords: '',
          phone: '',
        },
        /* table选中keys*/
        selectedRowKeys: [],
        /* table选中records*/
        selectionRows: [],

        /*url: {
          list: '/admin/member/feedback/page',
          deleteBatch: '/admin/member/feedback/delete',
        },*/
      }
    },
    created() {
      this.getList()
    },
    methods: {
      handleCloseDialog() {
        this.dailog.visible = false
      },
      reset() {
        for (let key in this.listQuery) {
          this.listQuery[key] = ''
        }
        this.listQuery.pageNumber = 1
        this.listQuery.pageSize = 20
        this.getList()
      },
      //查询
      getList() {
        this.loading = true
        feedBackList(this.listQuery).then((res) => {
          console.log(res)
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      handView(row) {
        console.log('row', row)
        this.dailog.visible = true
        this.drawerData = row
      },
      //选择
      onSelectChange(selectedRowKeys, selectionRows) {
        this.selectedRowKeys = selectedRowKeys
        this.selectionRows = selectionRows
      },
      //批量删除
      batchDel: function () {
        if (this.selectedRowKeys.length <= 0) {
          this.$message.warning('请选择一条记录！')
          return
        } else {
          var ids = ''
          for (var a = 0; a < this.selectedRowKeys.length; a++) {
            ids += this.selectedRowKeys[a].id + ','
          }
          console.log('ids', ids)
          var that = this
          this.$confirm('是否删除选中数据?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              deleteBatch({ ids: ids }).then((res) => {
                if (res.code === 0) {
                  that.$message.success('删除成功！')
                  that.getList()
                } else {
                  that.$message.warning('删除失败！')
                }
              })
            })
            .finally(() => {
              that.loading = false
            })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }
  .el-dialog .el-form-item__label {
    width: 150px !important;
  }
</style>
