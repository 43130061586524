<template>
  <div class="container">
    <div id="leftMap" style="height: 310px; width: 100%"></div>
    <div v-if="false" class="paiming">
      <div class="title">前三城市</div>
      <div v-for="(item, index) in topList" :key="index" class="item">
        <span class="label">{{ item.name }}:</span>
        <span>{{ item.value }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import * as echarts from 'echarts'
  let geoJson = require('./guangdong.json')
  import { houseDistribute } from '@/api/home/newIndex'
  export default {
    name: 'GuangdongMap',
    data() {
      return {
        myChart: null,
        chartData: [],
        topList: [],
        geoJson,
        noDrillCity: ['中山市', '东莞市'],
        parentInfo: [
          {
            cityName: '广东省',
            code: 440000,
          },
        ],
      }
    },
    mounted() {
      this.drawMap('广东省', this.geoJson)
      window.addEventListener('resize', () => {
        this.myChart.resize()
      })
    },
    methods: {
      async drawMap(map, data) {
        //防止重复注册
        if (!echarts.getMap(map)) echarts.registerMap(map, data)

        this.myChart = echarts.init(document.getElementById('leftMap'))
        this.myChart.showLoading()

        //数据
        const res = await houseDistribute()
        res.data.sort((a, b) => {
          return b.value - a.value
        })
        this.topList = res.data.slice(0, 3)
        let option = {
          series: [
            {
              name: '广东省',
              type: 'map',
              map,
              aspectScale: 1,
              zoom: 0.6,
              showLegendSymbol: false,
              label: {
                show: true,
                color: '#fff',
                fontSize: 10,
              },
              itemStyle: {
                areaColor: '#0E95F1',
                borderColor: '#e9e9e9',
                borderWidth: 1,
                shadowColor: '#0E95F1',
                shadowBlur: 20,
              },
              emphasis: {
                label: {
                  show: true,
                  color: '#fff',
                  fontSize: 10,
                },
                itemStyle: {
                  areaColor: '#FFD181',
                  borderColor: '#fff',
                  borderWidth: 1,
                },
              },
              layoutCenter: ['50%', '50%'],
              layoutSize: '160%',
              markPoint: {
                symbol: 'none',
              },
              data: res.data,
            },
          ],
          tooltip: {
            trigger: 'item',
          },
        }
        this.myChart.hideLoading()
        this.$nextTick(() => {
          this.myChart.setOption(option)
        })

        //取消监听，不然会重复触发点击事件
        this.myChart.off('click')
        //监听点击事件
        this.myChart.on('click', (param) => {
          if (this.geoJson[param.name]) {
            //将下钻数据保存
            this.parentInfo.push({
              cityName: param.name,
              code: param.data.code,
            })

            if (this.noDrillCity.includes(param.name)) return
            this.drawMap(param.name, this.geoJson[param.name], [])
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .container {
    position: relative;
    width: 100%;
    height: 100%;
    #leftMap {
      width: 100%;
      height: 100%;
    }

    a {
      position: absolute;
      right: 20%;
      top: 10%;
    }
    .paiming {
      position: absolute;
      right: 20px;
      bottom: 20px;
      .title,
      .label {
        color: #333;
      }
      .item,
      .title {
        margin-bottom: 8px;
      }
    }
  }
</style>
