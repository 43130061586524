<template>
  <div class="merchant-type">
    <titleVue title="商户类型" />
    <div id="merchantType" style="height: 310px; width: 100%"></div>
  </div>
</template>
<script>
  import * as echarts from 'echarts'
  import titleVue from './title.vue'
  import { pieChart } from '@/api/home/newIndex'
  export default {
    name: '',
    components: { titleVue },
    data() {
      return {
        leftChart: null,
        leftOption: {
          tooltip: {
            trigger: 'item',
          },
          series: [
            {
              type: 'pie',
              radius: ['40%', '70%'],
              data: [],
            },
          ],
        },
      }
    },
    created() {},
    mounted() {
      this.getLeftData()
      window.addEventListener('resize', () => {
        this.leftChart.resize()
      })
    },
    methods: {
      async getLeftData() {
        const leftDom = document.getElementById('merchantType')
        this.leftChart = echarts.init(leftDom)
        const { data } = await pieChart()
        this.leftOption.series[0].data = data
        this.leftChart.setOption(this.leftOption)
      },
    },
  }
</script>
<style scoped lang="scss">
  .merchant-type {
    background-color: #fff;
    #merchantType {
      border: 0.5px solid #e4e6e9;
    }
  }
</style>
