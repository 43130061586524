<template>
  <div class="merchant-listing-ranking">
    <titleVue title="商户房源排行" />
    <div class="merchant-listing-ranking-table">
      <el-table :data="tableData" height="295" style="width: 100%">
        <el-table-column label="排名" type="index" width="50" />
        <el-table-column label="姓名" prop="name" show-overflow-tooltip />
        <el-table-column label="数量" prop="num" />
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
    </div>
  </div>
</template>
<script>
  import titleVue from './title.vue'
  import { houseColumnarPic } from '@/api/home/newIndex'
  export default {
    name: '',
    components: { titleVue },
    data() {
      return {
        tableData: [],
      }
    },
    created() {},
    mounted() {
      this.getMidData()
    },
    methods: {
      getMidData() {
        houseColumnarPic().then((res) => {
          this.tableData = res.data || []
        })
      },
    },
  }
</script>
<style scoped lang="scss">
  .merchant-listing-ranking {
    background-color: #fff;

    .merchant-listing-ranking-table {
      height: 310px;
      padding: 15px 17px 0 20px;
      border: 0.5px solid #e4e6e9;
    }
  }
</style>
