var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c("el-page-header", {
        attrs: { content: _vm.tabTitle },
        on: { back: _vm.goBack },
      }),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            "label-width": "100px",
            model: _vm.formData,
            rules: _vm.rules,
            size: "small",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "帮助类别", prop: "helpCategoryId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "500px" },
                      attrs: { placeholder: "请选择帮助类别" },
                      model: {
                        value: _vm.formData.helpCategoryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "helpCategoryId", $$v)
                        },
                        expression: "formData.helpCategoryId",
                      },
                    },
                    _vm._l(_vm.helpCategoryList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.categoryName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "作者", prop: "author" } },
                [
                  _c("el-input", {
                    staticStyle: { "max-width": "500px" },
                    attrs: { clearable: "", placeholder: "请输入作者" },
                    model: {
                      value: _vm.formData.author,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "author", $$v)
                      },
                      expression: "formData.author",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "文章标题", prop: "helpTitle" } },
                [
                  _c("el-input", {
                    staticStyle: { "max-width": "500px" },
                    attrs: { clearable: "", placeholder: "请输入文章标题" },
                    model: {
                      value: _vm.formData.helpTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "helpTitle", $$v)
                      },
                      expression: "formData.helpTitle",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "文章摘要", prop: "digest" } },
                [
                  _c("el-input", {
                    staticStyle: { "max-width": "500px" },
                    attrs: { clearable: "", placeholder: "请输入文章摘要" },
                    model: {
                      value: _vm.formData.digest,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "digest", $$v)
                      },
                      expression: "formData.digest",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "内容", prop: "content" } },
                [
                  _c("JEditor", {
                    model: {
                      value: _vm.formData.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "content", $$v)
                      },
                      expression: "formData.content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "文章图标", prop: "helpLogo" } },
                [
                  _vm.formData.helpLogo != ""
                    ? _c("JMUpload", {
                        attrs: { multiple: false, number: 1 },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.formData.helpLogo,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "helpLogo", $$v)
                          },
                          expression: "formData.helpLogo",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "置顶状态", prop: "isTop" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formData.isTop,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "isTop", $$v)
                        },
                        expression: "formData.isTop",
                      },
                    },
                    _vm._l(_vm.topList, function (item) {
                      return _c(
                        "el-radio",
                        { key: item.value, attrs: { label: item.value } },
                        [_vm._v(" " + _vm._s(item.label) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发布状态", prop: "putaway" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formData.putaway,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "putaway", $$v)
                        },
                        expression: "formData.putaway",
                      },
                    },
                    _vm._l(_vm.putAwayList, function (item) {
                      return _c(
                        "el-radio",
                        { key: item.value, attrs: { label: item.value } },
                        [_vm._v(" " + _vm._s(item.label) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.tabUpdate == 0
                ? _c(
                    "el-button",
                    {
                      staticStyle: {
                        "margin-left": "20px",
                        display: "flex",
                        width: "200px",
                        "justify-content": "center",
                      },
                      attrs: { icon: "el-icon-circle-plus", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleConfirm()
                        },
                      },
                    },
                    [_vm._v(" 确认添加 ")]
                  )
                : _vm._e(),
              _vm.tabUpdate == 1
                ? _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-edit-outline", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleConfirm(_vm.formData)
                        },
                      },
                    },
                    [_vm._v(" 确认修改 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }