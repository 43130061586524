<template>
  <div class="listing-growth">
    <titleVue title="房源增长">
      <div class="btn-wrap">
        <span :class="{ active: rightActive === 1 }" @click="toggleRight(1)">
          近30天
        </span>
        <span :class="{ active: rightActive === 2 }" @click="toggleRight(2)">
          年度
        </span>
      </div>
    </titleVue>
    <div id="listingGrowth" style="height: 250px; width: 100%"></div>
  </div>
</template>
<script>
  import titleVue from './title.vue'
  import * as echarts from 'echarts'
  import { moneyBrokenLine, moneyYearBrokenLine } from '@/api/home/newIndex'
  export default {
    name: '',
    components: { titleVue },
    data() {
      return {
        rightActive: 1,
        rightChart: null,
        rightOption: {
          tooltip: {
            trigger: 'axis',
          },
          grid: {
            left: '5%',
            right: '5%',
            bottom: '1%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            data: [],
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              data: [],
              type: 'line',
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    // 渐变颜色
                    {
                      offset: 0,
                      color: '#5470C6',
                    },
                    {
                      offset: 1,
                      color: '#fff',
                    },
                  ],
                },
              },
            },
          ],
        },
      }
    },
    created() {},
    mounted() {
      this.getRightData()
      window.addEventListener('resize', () => {
        this.rightChart.resize()
      })
    },
    methods: {
      async getRightData() {
        const rightDom = document.getElementById('listingGrowth')
        this.rightChart = echarts.init(rightDom)
        await this.toggleRight(1)
        this.rightChart.setOption(this.rightOption)
      },
      async toggleRight(val) {
        this.rightActive = val
        if (val === 1) {
          const {
            data: { category, data },
          } = await moneyBrokenLine()
          this.rightOption.xAxis.data = category
          this.rightOption.series[0].data = data
        } else {
          const {
            data: { category, data },
          } = await moneyYearBrokenLine()
          this.rightOption.xAxis.data = category
          this.rightOption.series[0].data = data
        }
        this.rightChart.setOption(this.rightOption)
      },
    },
  }
</script>
<style scoped lang="scss">
  .listing-growth {
    background-color: #fff;

    #listingGrowth {
      border: 0.5px solid #e4e6e9;
    }

    .btn-wrap {
      font-weight: 400;
      font-size: 16px;
      color: #333;

      span {
        cursor: pointer;
      }

      .active {
        font-weight: 500;
        font-size: 16px;
        color: #1990ff;
      }
    }
  }
</style>
