var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "financial-data" },
    [
      _c("titleVue", { attrs: { title: "财务数据" } }),
      _vm._l(_vm.financialDataList, function (item, index) {
        return _c("div", { key: index, staticClass: "financial-data-item" }, [
          _c("div", { staticClass: "item-title" }, [_vm._v(_vm._s(item.name))]),
          _c("div", { staticClass: "item-center" }, [
            _c(
              "div",
              { staticClass: "item-center-left" },
              [
                _c("img", { attrs: { alt: "", src: item.icon } }),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      content: item.number + "",
                      effect: "dark",
                      placement: "top-start",
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(item.number))])]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "item-footer" }, [
            _vm._v(" 今日 "),
            _c("span", [_vm._v(_vm._s(item.todayNumber))]),
            _c("i", { staticClass: "iconfont icon-shangsheng1" }),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }