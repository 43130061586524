<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="90px"
          :model="queryParam"
          @submit.native.prevent
        >
          <el-form-item label="关键字" prop="keywords">
            <el-input
              v-model="queryParam.keywords"
              clearable
              placeholder="请输入关键字搜索"
              style="max-width: 300px"
            />
          </el-form-item>
          <el-form-item label="帮助分类">
            <el-select v-model="queryParam.helpCategoryId" clearable>
              <el-option
                v-for="item in helpCategoryList"
                :key="item.id"
                :label="item.categoryName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="发布状态">
            <el-select v-model="queryParam.putaway" clearable>
              <el-option
                v-for="item in putAwayList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="置顶状态">
            <el-select v-model="queryParam.isTop" clearable>
              <el-option
                v-for="item in topList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="getList">
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="reset">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel :span="24">
        <el-button icon="el-icon-circle-plus" type="primary" @click="addList">
          新增
        </el-button>

        <!-- <el-button
          icon="el-icon-edit-outline"
          type="primary"
          @click="auditList"
        >
          修改
        </el-button> -->

        <el-button icon="el-icon-delete-solid" type="danger" @click="deleteRow">
          删除
        </el-button>
        <!-- <el-button
          icon="el-icon-refresh"
          type="primary"
          @click="refresh($event)"
        >
          刷新
        </el-button> -->
      </vab-query-form-left-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="dataSource"
      stripe
      @select="selectRow"
      @select-all="selectRow"
    >
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="帮助类别"
        prop="helpCategoryName"
        width="200"
      />
      <el-table-column
        align="center"
        label="帮助标题"
        prop="helpTitle"
        width="200"
      />
      <el-table-column align="center" label="帮助封面" width="200">
        <!-- <template slot-scope="scope">
          <img alt="" height="40px" :src="scope.row.helpLogo" width="40px" />
        </template> -->

        <template slot-scope="scope">
          <el-image
            v-if="scope.row.helpLogo"
            :preview-src-list="[scope.row.helpLogo]"
            :src="scope.row.helpLogo"
            style="width: 50px; height: 50px"
          />
          <el-image
            v-else
            :preview-src-list="[defaultImg]"
            :src="defaultImg"
            style="width: 50px; height: 50px"
          />
        </template>

        <!-- <template slot-scope="scope">
          <el-popover placement="right" title="" trigger="hover">
            <el-image
              slot="reference"
              :alt="scope.row.helpLogo"
              :src="scope.row.helpLogo"
              style="max-width: 100px; max-height: 100px"
            />
            <el-image
              :src="scope.row.helpLogo"
              style="max-width: 300px; max-height: 300px"
            />
          </el-popover>
        </template> -->
      </el-table-column>
      <el-table-column
        align="center"
        label="浏览量"
        prop="visitCount"
        width="250"
      />
      <el-table-column
        align="center"
        label="帮助摘要"
        prop="digest"
        width="250"
      />
      <el-table-column align="center" label="发布状态" width="150">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.putaway">
            {{ format(scope.row.putaway, putAwayList) }}
          </el-tag>
          <el-tag v-else type="warning">
            {{ format(scope.row.putaway, putAwayList) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="是否置顶" width="150">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isTop">
            {{ format(scope.row.isTop, topList) }}
          </el-tag>
          <el-tag v-else type="warning">
            {{ format(scope.row.isTop, topList) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="置顶时间"
        prop="topTime"
        width="200"
      />
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        width="250"
      />

      <el-table-column align="center" fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="openEdit(scope.row)">
            修改
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="formData"
          label-width="100px"
          :model="formData"
          :rules="rules"
          size="small"
        >
          <el-row>
            <el-form-item label="帮助类别" prop="helpCategoryId">
              <el-select
                v-model="formData.helpCategoryId"
                placeholder="请选择帮助类别"
                style="width: 500px"
              >
                <el-option
                  v-for="item in helpCategoryList"
                  :key="item.id"
                  :label="item.categoryName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="作者" prop="author">
              <el-input
                v-model="formData.author"
                clearable
                placeholder="请输入作者"
                style="max-width: 500px"
              />
            </el-form-item>
            <el-form-item label="文章标题" prop="helpTitle">
              <el-input
                v-model="formData.helpTitle"
                clearable
                placeholder="请输入文章标题"
                style="max-width: 500px"
              />
            </el-form-item>
            <el-form-item label="文章摘要" prop="digest">
              <el-input
                v-model="formData.digest"
                clearable
                placeholder="请输入文章摘要"
                style="max-width: 500px"
              />
            </el-form-item>
            <el-form-item label="内容" prop="content">
              <!-- <vab-quill
                ref="vab-quill"
                v-model="formData.content"
                :min-height="300"
                :options="options"
                style="width: 90%"
              /> -->
              <JEditor v-model="formData.content" />
              <!-- <quill-editor v-model="formData.content" :options="options" /> -->
            </el-form-item>
            <el-form-item :label="'文章图标'" prop="helpLogo">
              <JUpload
                v-if="formData.helpLogo != ''"
                v-model="formData.helpLogo"
                :multiple="false"
                :number="1"
                @change="handleChange"
              />
            </el-form-item>
            <el-form-item label="置顶状态" prop="isTop">
              <el-select
                v-model="formData.isTop"
                placeholder="请选择发布状态"
                style="width: 500px"
              >
                <el-option
                  v-for="item in topList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="发布状态" prop="putaway">
              <el-select
                v-model="formData.putaway"
                placeholder="请选择发布状态"
                style="width: 500px"
              >
                <el-option
                  v-for="item in putAwayList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-row>
        </el-form>
      </template>
    </Dialog>
  </div>
</template>

<script>
  // import 'quill/dist/quill.core.css'
  // import 'quill/dist/quill.snow.css'
  // import 'quill/dist/quill.bubble.css'
  // import Quill from 'quill'
  // import { quillEditor } from 'vue-quill-editor'
  // import ImageResize from 'quill-image-resize-module' //添加
  // Quill.register('modules/imageResize', ImageResize) //添加

  import {
    // helpInformationList,
    // deleteHelpInformation,
    getHelpCategory,
    addHelpInformation,
    editHelpInformation,
  } from '@/api/help/help'
  import JUpload from '@/components/j-upload'
  import JEditor from '@/components/j-editor'
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  //import VabQuill from '@/extra/VabQuill'
  import { getUploadToken } from '@/api/common/index'
  import { BasePostMixin } from '@/mixins/BasePostMixin'
  const uploadUrlConf = {
    'qiniu.region.z0': '//upload.qiniup.com',
    'qiniu.region.z2': '//upload-z2.qiniup.com',
    'qiniu.region.z1': '//upload-z1.qiniup.com',
  }
  export default {
    name: 'HelpCenterInformation',
    components: {
      Pagination,
      Dialog,
      //VabQuill,
      JEditor,
      JUpload,
      // quillEditor,
    },
    mixins: [BasePostMixin],
    data() {
      return {
        defaultImg: require('@/assets/default.jpg'),
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
        },
        queryParam: {
          pageNumber: 1,
          pageSize: 20,
          helpCategoryId: '',
          putaway: '',
          isTop: '',
          keywords: '',
        },
        dailog: {
          height: 620,
          title: '文章',
          visible: false,
          width: '800px',
        },
        articleCategoryList: [
          {
            value: '',
            label: '帮助分类',
          },
          {
            value: 1535817913636884480,
            label: '其他问题',
          },
          {
            value: 1535818101453623296,
            label: '功能相关',
          },
          {
            value: 1535818298552356864,
            label: '账号相关',
          },
          {
            value: 1536392776936198144,
            label: '硬件相关',
          },
        ],

        rules: {
          helpCategoryId: [
            { required: true, message: '请选择帮助类别', trigger: 'blur' },
          ],
          author: [{ required: true, message: '请输入作者', trigger: 'blur' }],
          helpTitle: [
            { required: true, message: '请输入标题', trigger: 'blur' },
          ],
          digest: [
            { required: true, message: '请输入文章摘要', trigger: 'blur' },
          ],
          content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
          helpLogo: [
            { required: true, message: '请上传文章图标', trigger: 'blur' },
          ],
          isTop: [
            { required: true, message: '请选择置顶状态', trigger: 'blur' },
          ],
          putaway: [
            { required: true, message: '请选择发布状态', trigger: 'blur' },
          ],
        },
        formData: {},
        totalCount: 0,
        dataSource: [],
        tableData1: [],
        helpCategoryList: [
          // {
          //   value: '',
          //   label: '帮助分类',
          // },
          // {
          //   value: '1535817913636884480',
          //   label: '其他问题',
          // },
          // {
          //   value: '1535818101453623296',
          //   label: '功能相关',
          // },
          // {
          //   value: '1535818298552356864',
          //   label: '账号相关',
          // },
          // {
          //   value: '1536392776936198144',
          //   label: '硬件相关',
          // },
        ],
        putAwayList: [
          {
            value: '',
            label: '发布状态',
          },
          {
            label: '未发布',
            value: 0,
          },
          {
            label: '已发布',
            value: 1,
          },
        ],
        topList: [
          {
            value: '',
            label: '置顶状态',
          },
          {
            label: '未置顶',
            value: 0,
          },
          {
            label: '已置顶',
            value: 1,
          },
        ],
        loading: false,
        // 格式化
        format(val, list) {
          const res = list.filter((item) => item.value === val)
          return res[0].label
        },
        options: {
          theme: 'snow',
          bounds: document.body,
          debug: 'warn',
          modules: {
            toolbar: {
              container: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ color: [] }, { background: [] }],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ align: [] }],
                [{ direction: 'rtl' }],
                [{ font: [] }],
                ['clean'],
                ['link', 'image', 'vab-upload-image'],
              ],
              handlers: {
                'vab-upload-image': () => {
                  this.$baseConfirm(
                    '演示环境未使用真实文件服务器，故图片上传回显不会生效，开发时请修改为正式文件服务器地址',
                    '开发注意事项！！！',
                    () => {
                      this.$refs['vabUpload'].handleShow()
                    },
                    () => {
                      this.handleAddImg()
                    },
                    '模拟打开文件上传',
                    '模拟添加一张文件服务器图片'
                  )
                },
              },
            },
            imageResize: {
              //控制图片编辑的，实现功能就是这一段代码
              displayStyles: {
                backgroundColor: 'black',
                border: 'none',
                color: 'white',
              },
              modules: ['Resize', 'DisplaySize', 'Toolbar'],
            },
          },
          placeholder: '内容...',
          readOnly: false,
        },
        QiniuData: {
          key: '', //图片名字处理
          token: '', //七牛云token
          data: {},
        },
        domain: '', // 七牛云的上传地址（华东区）
        uploadUrl: '', //提交到后台图片地址
        fileList: [],
        urlList: {},

        selectedRowKeys: [],
        check: {},
        ids: '', //删除用
        url: {
          list: '/admin/help/page',
          // handleEditUrl: '/admin/help/update',
          deleteBatch: '/admin/help/delete',
        },
      }
    },
    created() {
      // this.getList()
      this.getHelpCategory()
    },
    methods: {
      getHelpCategory() {
        const param = { pageNumber: 1, pageSize: 20 }
        console.log(param)
        getHelpCategory(param).then((res) => {
          console.log('编辑帮助信息返回消息分类', res)
          const { records } = res.data
          this.helpCategoryList = records
        })
      },
      handleChange(value) {
        console.log('value', value)
        this.formData.helpLogo = value
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      //表格选择
      selectRow(selection, row) {
        this.selectedRowKeys = selection
        console.log('单选===', selection)
        console.log('单选===', row)
        this.check = row
        this.ids = ''
        selection.forEach((i) => {
          this.ids += i.id + ','
        })
        console.log(this.ids)
      },

      //查询
      async getList() {
        this.loadData()
        // this.loading = true
        // const {
        //   data: { list, totalRow },
        // } = await helpInformationList(this.queryParam)
        // this.loading = false
        // this.tableData = list
        // console.log('list:', list)
        // this.totalCount = totalRow
      },
      //新增
      handleAdd() {
        this.dailog.visible = true
        this.dailog.title = '新增文章'
        this.fileList = []
      },
      // 确定新增/修改
      handleConfirm() {
        this.$refs['formData'].validate((valid) => {
          console.log(valid)
          if (valid) {
            this.formData.title = this.formData.helpTitle
            this.formData.logo = this.formData.helpLogo
            this.$delete(this.formData, 'helpTitle')
            this.$delete(this.formData, 'helpLogo')
            if (this.dailog.title === '新增帮助信息') {
              addHelpInformation(this.formData).then((res) => {
                this.commonMessage(res)
                this.handleCloseDialog()
              })
            } else {
              // console.log('this.formData：', this.formData)
              editHelpInformation(this.formData).then((res) => {
                this.commonMessage(res)
                this.handleCloseDialog()
              })
            }
          }
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.$refs['formData'].resetFields()
      },
      commonMessage(res) {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
          })
        }
      },
      /** 七牛上传 */
      handleRemove() {
        this.uploadUrl = ''
      },
      async beforeUpload(file) {
        const fileName = file.name || file.path
        let ext = fileName.substring(
          fileName.lastIndexOf('.') + 1,
          fileName.length
        )
        let res = await getUploadToken({
          extension: ext,
        })
        console.log(' this.domain', this.domain)
        if (res.code === 0) {
          this.domain = uploadUrlConf[res.data.region]
          console.log(' this.domain', this.domain)
          this.QiniuData = {
            data: file,
            token: res.data.token,
            key: res.data.key,
          }
          this.uploadUrl = res.data.url
          this.formData.helpLogo = this.uploadUrl
          console.log(res.data.url, 'res.data.url==>')
          this.dialogImageUrl = this.uploadUrl
          return true
        }
        return false
      },
      uploadSuccess(response, file) {
        this.urlList = {
          uid: file.uid,
          url: this.uploadUrl,
        }
        this.uploadUrl = ''
      },
      // 上传失败
      uploadError() {
        this.uploadUrl = ''
        this.$message({
          message: '上传出错，请重试！',
          type: 'error',
          center: true,
        })
      },
      onExceed() {
        this.$message({
          message: '最多可上传1张图片',
          type: 'error',
          center: true,
        })
      },
      onRemove(file) {
        const arr = this.urlList.filter((o) => {
          return o.uid !== file.uid
        })
        this.urlList = arr
        this.formData.helpLogo = ''
      },
      reset() {
        this.queryParam = {
          pageNumber: 1,
          pageSize: 20,
          helpCategoryId: '',
          putaway: '',
          isTop: '',
          keywords: '',
        }
        this.getList()
      },

      //新增
      // addList() {
      //   this.formData = {}
      //   this.dailog.visible = true
      //   this.dailog.title = '新增帮助信息'
      // },
      addList() {
        console.log('addList')
        this.$nextTick(() => {
          this.$router.push({
            path: '/helpCenter/informationOperation',
            query: { id: 0 },
          })

          // this.$router.push({
          //   path: '/helpCenter/informationOperation',
          //   query: { id: 0 },
          // })
        })
      },
      openEdit(row) {
        this.$nextTick(() => {
          this.$router.push({
            path: '/helpCenter/informationOperation',
            query: { id: row.id },
          })
          // this.$router.push({ name: 'informationOperation', params: row })
        })

        // // if (this.selectedRowKeys.length <= 0) {
        // //   this.$message.warning('请选择一条记录！')
        // //   return
        // // } else {
        // this.dailog.visible = true
        // this.dailog.title = '修改帮助信息'
        // this.formData = row
        // // this.formData.title = row.helpTitle
        // // this.formData.logo = row.helpLogo
        // console.log(row)
        // // }
      },

      //修改
      auditList() {
        if (this.selectedRowKeys.length == 0) {
          this.$message({
            message: '请至少选择一行数据',
            type: 'warning',
          })
          return false
        }
        if (this.selectedRowKeys.length > 1) {
          this.$message({
            message: '最多选择一行数据',
            type: 'warning',
          })
          return false
        }
        this.dailog.visible = true
        this.dailog.title = '修改帮助信息'
        this.formData = this.check
        // this.formData.title = this.check.helpTitle
        // this.formData.logo = this.check.helpLogo
        console.log('this.formData:', this.formData)
      },
      //删除
      deleteRow() {
        this.batchDel()

        // if (this.selection.length == 0) {
        //   this.$message({
        //     message: '请至少选择一行数据',
        //     type: 'warning',
        //   })
        //   return false
        // }

        // this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning',
        // })
        //   .then(() => {
        //     deleteHelpInformation({ ids: this.ids }).then((res) => {
        //       console.log('res', res)
        //       if (res.code == 0) {
        //         this.check = {}
        //         this.selection = []
        //         this.$message({
        //           type: 'success',
        //           message: '删除成功!',
        //         })
        //         this.getList()
        //       }
        //     })
        //   })
        //   .catch(() => {})
      },
      //刷新
      refresh() {
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .index-container {
    padding: 0 !important;
    .addButton {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
  }
  .el-range-editor.el-input__inner {
    width: 105%;
  }
</style>
