<template>
  <div class="title">
    <div>{{ title }}</div>
    <div class="title-slot"><slot></slot></div>
  </div>
</template>
<script>
  export default {
    name: '',
    props: {
      title: {
        type: String,
        default: '',
      },
    },
    data() {
      return {}
    },
    created() {},
  }
</script>
<style scoped lang="scss">
  .title {
    height: 67px;
    line-height: 67px;
    display: flex;
    font-weight: 500;
    padding-left: 19.5px;
    font-size: 20px;
    background: #fff;
    box-sizing: border-box;
    border: 0.5px solid #e4e6e9;
    .title-slot {
      flex: 1;
      text-align: right;
      padding-right: 19px;
    }
  }
</style>
